<template>
  <div class="w-full pt-24">
    <the-top-bar
      :add="'Novi Post'"
      :icon="'fas fa-blog'"
      :link="'/dilektra-cms/blog/add'"
      :title="'Blog'"
    ></the-top-bar>
    <added-post-succes
      v-show="trackSucces"
      :message="'Uspesno ste obrisali post.'"
    ></added-post-succes>
    <section class="px-8 py-4 mx-auto w-full">
      <!-- grid gap-8 grid-cols-4 -->
      <div class="flex flex-wrap">
        <div v-for="post in trackBlogPosts" :key="post.post_id">
          <Post
            :id="post.post_id"
            :createdAt="post.createdAt"
            :text="post.text"
            :title="post.title"
            :image="post.images[post.images.length - 1]"
            :category="post.category"
            :name="post.name"
            :lastname="post.lastname"
            @deleted-post="fetchPosts"
          />
        </div>
        {{ this.stiglaPoruka }}
      </div>
      <no-content
        :listLength="this.trackBlogPosts.length"
        :message="'Nemate Blog postova molim vas dodajte.'"
      ></no-content>
    </section>
  </div>
</template>

<script>
import NoContent from "../partials/NoContent.vue";
import Post from "../partials/TheBlogPostThumbnail.vue";
import TheTopBar from "../partials/TheTopBar.vue";
import AddedPostSucces from "../../modals/AddedSuccess.vue";
export default {
  components: { Post, NoContent, TheTopBar, AddedPostSucces },
  data() {
    return {
      allPosts: [],
      stiglaPoruka: "",
    };
  },
  mounted() {
    this.getAllPosts();
  },
  computed: {
    trackBlogPosts() {
      return this.$store.getters.get_allPosts;
    },
    trackSucces(){
      return this.$store.getters.get_succes
    }
  },
  methods: {
    async getAllPosts() {
      await this.$store.dispatch("getAllPosts");
      this.allPosts = await this.$store.getters.get_allPosts;
    },
    async fetchPosts() {
      await this.$store.dispatch("getAllPosts");
      this.allPosts = await this.$store.getters.get_allPosts;
    },
  },
};
</script>

<style></style>
