<template>
  <div class="hover:shadow-xl p-4 transition-shadow min-max-width">
    <div class="relative">
      <div class="absolute top-0 left-0">
        <button
          @click="toggleEdit"
          class="w-10 h-9 flex justify-center items-center bg-yellow-dark text-white rounded-br-md rounded-tl-sm"
        >
          <i class="fas fa-bars"></i>
        </button>
        <transition name="fade">
          <div
            v-if="showEdit"
            class="flex flex-col gap-1 mt-1 ml-1 bg-transparent rounded-md"
          >
            <router-link
              :to="{ name: 'EditPost', params: { id } }"
              class="btn btn-light"
            >
              <i class="fas fa-edit"></i> Izmeni
            </router-link>
            <button class="btn btn-light text-red-400" @click="areYouSure">
              <i class="far fa-trash-alt text-base text-red-400"></i> Obrisi
            </button>
          </div>
        </transition>
      </div>
      <img
        :src="$store.getters.get_API + '/images/' + image"
        @error="imageErrorHandler"
        class="object-cover w-full h-56 mb-5 bg-center rounded"
        alt="blog image"
      />
    </div>
    <h2 class="mb-2 text-lg font-semibold text-gray-900">
      <a href="#" class="text-gray-900 hover:text-yellow">{{ title }}</a>
    </h2>
    <p class="mb-3 text-sm font-normal text-gray-500">
      {{ this.reduceText(text) }}
    </p>
    <p class="mb-3 text-sm font-normal text-gray-500">
      <span class="font-medium text-yellow hover:text-yellow-light capitalize">
        {{ name }} {{ lastname }}
      </span>
     
      • {{  this.postedOn(createdAt) }} •
      <span class="capitalize">{{ category }}</span>
    </p>
    <div class="fixed inset-0 w-full h-full z-50" v-if="showModal">
      <div
        class="fixed inset-0 bg-transparent flex items-center justify-center"
      >
        <div class="w-auto mx-auto shadow-xl py-5 px-5 bg-gray-100 rounded-md">
          <div class="flex pb-3 items-center">
            <div class="text-gray-600">
              <i class="far fa-trash-alt text-xl text-red-400"></i>
            </div>
            <p class="text-lg text-gray-800 font-semibold pl-2">
              Obrisi blog post?
            </p>
          </div>
          <p class="text-sm text-gray-600 pb-4 font-normal">
            Da li ste sigurni da zelite da obrisete post?
          </p>
          <div class="flex justify-start">
            <button
              @click="deleteBlogPost(id)"
              class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs mr-5"
            >
              Obrisi Post
            </button>
            <button
              @click="areYouSure"
              class="focus:outline-none bg-yellow transition duration-150 ease-in-out hover:bg-yellow-light rounded text-white px-3 py-2 text-xs"
            >
              Odustani
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeHandler from "../../../middleware/TimeHandler";
export default {
  props: [
    "name",
    "lastname",
    "createdAt",
    "category",
    "title",
    "text",
    "image",
    "id",
  ],
  data() {
    return {
      showEdit: false,
      showModal: false,
    };
  },
  mouted() {
    this.imageErrorHandler();
  },
  methods: {
    postedOn(time) {
      return TimeHandler.SET_DATE(time);
    },
    reduceText(text) {
      return text.substring(100, text.lenght - 1) + "...";
    },
    imageErrorHandler(event) {
      event.target.src = "https://via.placeholder.com/400x200";
    },
    toggleEdit() {
      this.showEdit = !this.showEdit;
    },
    areYouSure() {
      this.showModal = !this.showModal;
    },
    async deleteBlogPost(id) {
      await this.$store.dispatch("deletePost", id);
      this.showModal = false;
      this.$emit("deletedPost");
    },
  },
};
</script>

<style>
  .min-max-width{
    width: 300px;
  }
</style>
